import { useState, useContext, useEffect, useRef } from 'react';
import Link from 'next/link';
import Image from 'next/image';

import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  useInstantSearch,
  useHits,
  Configure,
} from 'react-instantsearch-hooks-web';

// Font Awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowTurnDownRight,
  faArrowRight,
  faArrowLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';

// Swiper-related imports
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/free-mode';
import { Navigation, FreeMode, Scrollbar } from 'swiper';

// Custom components
import ProductCard from '../../components/product/ProductCard';
import StoreContext from '../../context/store-context';
import Button from '../../components/Button/button';

// Styles
import styles from './ProductSlider.module.scss';

// Icons
import Sale from '../../public/icons/sale.svg';
import Flower from '../../public/icons/flower.svg';
import Preroll from '../../public/icons/pre-roll.svg';
import Extract from '../../public/icons/extract.svg';
import Vape from '../../public/icons/vape.svg';
import Edible from '../../public/icons/edible.svg';
import Tincture from '../../public/icons/tincture.svg';
import Topical from '../../public/icons/topical.svg';
import Gear from '../../public/icons/gear.svg';
import Merch from '../../public/icons/merch.svg'
import Award from '../../public/icons/award.svg'

import SkeletonElement from '../../components/skeletons/SkeletonElement';
import SkeletonCard from '../../components/skeletons/SkeletonCard';

const searchClient = algoliasearch(process.env.NEXT_PUBLIC_ALGOLIA_ID, process.env.NEXT_PUBLIC_ALGOLIA_KEY);
// const indexName = 'menu-products-by-category-and-name-asc-production'
const indexName = "menu-products-by-rating-production";

export default function ProductSlider({ title, subtitle, root_types, strain, brand, special, icon, storeId, router, specials, noIcon, product_id }) {

  const { store } = useContext(StoreContext);
  const [filters, setFilters] = useState();

  // conditionally set filters for algolia configure
  useEffect(() => {
    // if (!store) return;

    let filterConditions = [];

    if (root_types) filterConditions.push(`root_types:"${root_types}"`);
    if (strain) filterConditions.push(`category:"${strain}"`);
    if (brand) filterConditions.push(`brand:"${brand}"`);
    if (special) filterConditions.push(`special:"${special}"`);
    if (product_id) filterConditions.push(`NOT product_id:${product_id}`); // Exclude current product

    const filters = filterConditions.join(' AND ');
    if (filters) {
      setFilters(filters);
    }
  }, [store, strain, brand, root_types, special, product_id]);

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indexName}
    >
      <Configure
        hitsPerPage="11"
        filters={`store_id:${storeId ? storeId : store} AND ${filters}`}
      />
      <HitsSwiper
        root_types={root_types}
        strain={strain}
        title={title}
        brand={brand}
        subtitle={subtitle}
        specials={specials}
        special={special}
        router={router}
        noIcon={noIcon}
      />
    </InstantSearch>
  );
}

function HitsSwiper(props) {
  const { hits, loading, error } = useHits(props);
  const [swiper, setSwiper] = useState({});
  const { title, subtitle, type, root_types, strain, brand, special, specials, router, noIcon } = props
  const swiperRef = useRef(null);
  //swiper ID for navigation elements
  const swiperEle = title?.replace(/[^\w]+/g, '');

  function getLastSlideHref() {
    if (root_types) return `/shop/${root_types}`;
    if (brand) return `/brands/${brand}`;
    if (special) return `/shop?special=${special}`;
    if (strain) return `/shop?strain=${strain}`;
    return '/shop'; // default fallback
  }

  const categories = [
    {
      label: "sale",
      image: "/images/deals.jpg",
      icon: <Sale />
    },
    {
      label: "Best Sellers-4398",
      image: "/images/deals.jpg",
      icon: <Award />
    },
    {
      label: "flower",
      image: "https://cdn.sanity.io/images/10gj8xqe/production/2a015cc54d613b5529c55530cf4fd46ffb279af5-600x900.jpg",
      icon: <Flower />
    },
    {
      label: "pre-roll",
      image: "https://cdn.sanity.io/images/10gj8xqe/production/e8b3d3e0b206182d0dd1ab616c06b6b14ba322a1-1500x852.jpg",
      icon: <Preroll />
    },
    {
      label: "extract",
      image: "https://cdn.sanity.io/images/10gj8xqe/production/7cc0fe18a63d651f420c16fb62009491969a765c-600x899.jpg",
      icon: <Extract />
    },
    {
      label: "vape",
      image: "https://cdn.sanity.io/images/10gj8xqe/production/429fe437aa38586346632278baf5a354ba9b1531-600x900.jpg",
      icon: <Vape />
    },
    {
      label: "edible",
      image: "https://cdn.sanity.io/images/10gj8xqe/production/02b9caae0c983a78379fdb1fd9042e60d039a81f-1500x693.jpg",
      icon: <Edible />
    },
    {
      label: "tincture",
      image: "https://cdn.sanity.io/images/10gj8xqe/production/f2a899628a5b1d1cb706b7b207bbc3ed9d179d92-1500x1000.jpg",
      icon: <Tincture />
    },
    {
      label: "topical",
      image: "https://cdn.sanity.io/images/10gj8xqe/production/92803246e21fd9ed1156baf32d397bf95660a7f4-600x900.jpg",
      icon: <Topical />
    },
    {
      label: "gear",
      image: "https://cdn.sanity.io/images/10gj8xqe/production/100e416113390189c8e7bb5f00418b531e96176a-1500x1003.jpg",
      icon: <Gear />
    },
    {
      label: "merch",
      image: "https://cdn.sanity.io/images/10gj8xqe/production/7528b6fc2dda6e000d1467ff867b1e11d6239f5d-1500x1034.jpg",
      icon: <Merch />
    },
  ];

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <>
      {loading ? (
        <div>
          <SkeletonElement type="title-large" className="ml-100" style={{ height: "150px" }} />
          <div className="reel">
            <SkeletonElement type="product-card" />
            <SkeletonElement type="product-card" />
            <SkeletonElement type="product-card" />
            <SkeletonElement type="product-card" />
          </div>
        </div>
      ) : hits.length > 0 ? (
        <div className={styles.slider}>
          <div className={styles.header}>
            <div className={styles.header__content}>
              <div className={styles.title}>

                {!noIcon && (
                  <div className={styles.icon}>
                    {categories.map(category => {
                      if (category.label === root_types) {
                        return category.icon;
                      }
                    })}
                  </div>
                )}

                <div>
                  <h2>{title}</h2>
                  {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
                </div>
              </div>

            </div>
            <div className="slider-link">
              {/* Render stats component */}
              <Stats type={type} root_types={root_types} strain={strain} brand={brand} specials={specials} />
            </div>
          </div>
          <div className={`${styles.container} product-slider`}>
            <Swiper
              onInit={(ev) => {
                setSwiper(ev);
              }}
              modules={[Navigation, FreeMode, Scrollbar]}
              className={styles.productSwiper}
              spaceBetween={16}
              touchEventsTarget='container'
              breakpoints={{
                1024: {
                  slidesPerView: 4,
                  slidesPerGroup: 4,
                  spaceBetween: 16,
                },
                768: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                  spaceBetween: 14,
                },
                370: {
                  slidesPerView: 2,
                  slidesPerGroup: 1,
                  spaceBetween: 10,
                },
                0: {
                  slidesPerView: 1.2,
                  slidesPerGroup: 1,
                  spaceBetween: 10,
                }
              }}
              navigation={{
                prevEl: `#${swiperEle}-prev`,
                nextEl: `#${swiperEle}-next`,
                clickable: true,
              }}
              scrollbar={{
                hide: true,
              }}
              onSwiper={(swiper) => (swiperRef.current = swiper)}
            >
              {/* nav buttons */}
              <div className={styles.prev} id={`${swiperEle}-prev`}>
                <Button type="button" className="slider-arrow" ariaLabel="Previous Slide">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Button>
              </div>
              <div className={styles.next} id={`${swiperEle}-next`}>
                <Button type="button" className="slider-arrow" ariaLabel="Next Slide">
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </div>
              {/* Product slides */}
              {hits.map(hit => {
                const isProductPage = router?.query.id === hit.product_id;
                // Render the slide only if the product_id is not in the pathname
                if (!isProductPage) {
                  return (
                    <SwiperSlide key={hit.product_id}>
                      <ProductCard hit={hit} />
                    </SwiperSlide>
                  );
                }
                return null;
              })}
              {/* last Slide */}
              <SwiperSlide className={styles.lastSlide}>
                <Link href={getLastSlideHref()}>View All</Link>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      ) : (
        <></> // Render message if no hits are returned
      )}
    </>
  );

}


function Stats({ root_types, brand, strain }) {
  const { scopedResults } = useInstantSearch();

  return (
    <div>
      {scopedResults.map(({ indexId, results }) => {
        let href = "";
        if (brand) {
          href = `/brands/${brand.replace(/ /g, "-")}`;
        } else if (strain) {
          href = `/shop/?strain=${strain}`;
        } else {
          href = `/shop/${root_types}`;
        }

        return (
          <Link
            href={href}
            className={styles.seeAll}
            key={indexId}
          >
            <Button type="link" href={href} className="sliderLink" prefetch={false} ariaLabel="View All">
              view all
            </Button>
            ({results.nbHits})
          </Link>
        );
      })}
    </div>
  );
}
